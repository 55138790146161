import { Button } from "@aws-amplify/ui-react";

export default function BookControls(props) {
  return (
    <div className="bookControls" id="bookControls">
      <Button variation="primary" className="navyButton" colorTheme="success" onClick={(e) => window.location='/'}>✨ New</Button>
      <Button variation="primary" className="navyButton" colorTheme="success" onClick={(e) => props.shareClickHandler()}>📬 Share</Button>
      <Button variation="primary" className="navyButton" colorTheme="success" onClick={(e) => props.loadClickHandler()}>📤 Load</Button>
    </div>
  );
}